<template>
  <section>View Collection slug: {{ slug }}</section>
</template>
<script>
export default {
  name: "viewCollection",
  props: {
    slug: {
      type: String,
      default: "",
    },
  },
};
</script>
